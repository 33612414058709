  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  * {
    box-sizing: border-box;
  }
  
  .row {
    display: flex;
    margin-left:-5px;
    margin-right:-5px;
  }
  
  .column {
    flex: 50%;
    padding: 2px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  /* tr:nth-child(even) {
    background-color: #f2f2f2;
  } */

.totalOrderTable > tr:first-child {
    background-color: gold;
}

.logo-dark-colour-filter {
  filter: invert(85%) sepia(11%) saturate(96%) hue-rotate(178deg) brightness(98%) contrast(85%);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
